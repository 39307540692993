import '@/content/global.scss';
import 'font-awesome/scss/font-awesome.scss'; //used by notifications
import 'chart.js/auto';

import './index.scss';

import constants from '@/resources/constants';
import { msalConfig } from '@/services/auth-config';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Chart } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { version } from '../package.json';

import reportWebVitals from './reportWebVitals';
import { CacheBuster } from './components/cache-buster';

let msalInstanceField = buildMsalInstance();
Chart.register(annotationPlugin);

export const msalInstance = () => {
  return msalInstanceField;
}

if (!msalInstanceField.getActiveAccount() && msalInstanceField.getAllAccounts().length > 0) {
  msalInstanceField.setActiveAccount(msalInstanceField.getAllAccounts()[0]);
}

msalInstanceField.addEventCallback((event: any) => {
  var accounts = msalInstanceField.getAllAccounts();
  if (accounts.length > 1) {
    let nonSignInAccount = accounts.find(a => !!a.idTokenClaims
      && a.idTokenClaims[constants.B2C_CLAIM_POLICY] !== (window as any).REACT_APP_B2C_SIGNIN_POLICY.toLowerCase()
      && !!a.idTokenClaims[constants.B2C_CLAIM_POLICY]);

    msalInstanceField.logoutRedirect({
      account: nonSignInAccount,
      postLogoutRedirectUri: window.location.href
    });
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstanceField.setActiveAccount(account);
  }
  else if (event.eventType === EventType.ACCOUNT_REMOVED) {
    window.location.href = "/";
  }
  else if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error('An error occurred during login.');
    sessionStorage.setItem('factor.signUpFailureOccurred', '1');
  }
});

function buildMsalInstance(policy?: string) {
  let instance = new PublicClientApplication(msalConfig(policy));
  // Optional - This will update account state if a user signs in from another tab or window
  instance.enableAccountStorageEvents();
  return instance;
}

const container = document.getElementById('root');
const root = createRoot(container!);
import(/* webpackMode: "lazy" */'./app')
  .then(app => {
    root.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstanceField}>
          <CookiesProvider>
            <CacheBuster
              currentVersion={version}
              isEnabled={process.env.NODE_ENV === 'production'}
            >
              <app.default />
            </CacheBuster>
          </CookiesProvider>
        </MsalProvider>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
