// @ts-check
import { compare } from 'compare-versions';
import React, {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

const CacheBusterContext = createContext({ checkCacheStatus: () => { } });

interface Props extends PropsWithChildren<Record<never, any>> {
  currentVersion: string,
  isEnabled: boolean
}

const CacheBuster: FunctionComponent<Props> = ({ children, ...props }) => {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false
  });

  const log = (message: any) => {
    console.log(message);
  };

  useEffect(() => {
    !!props.isEnabled ? checkCacheStatus() : log('React Cache Buster is disabled.');
  }, []);

  const checkCacheStatus = useCallback(async () => {
    try {
      const res = await fetch(`/meta.json?${Date.now()}`);
      const { version: metaVersion } = await res.json();

      const shouldForceRefresh = isThereNewVersion(metaVersion, props.currentVersion);
      if (shouldForceRefresh) {
        log(`There is a new version (v${metaVersion}). Should force refresh.`);
        setCacheStatus({
          loading: false,
          isLatestVersion: false
        });
      } else {
        log('There is no new version. No cache refresh needed.');
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
      }
    } catch (error) {
      log('An error occurred while checking cache status.');
      log(error);

      setCacheStatus({
        loading: false,
        isLatestVersion: true
      });
    }
  }, [props.currentVersion]);

  const isThereNewVersion = (metaVersion: string, currentVersion: string) => {
    return compare(metaVersion, currentVersion, '>');
  };

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

        await Promise.all(cacheDeletionPromises);

        log('The cache has been deleted.');
        // @ts-ignore: Firefox still has a `forceReload` parameter.
        window.location.reload(true);
      }
    } catch (error) {
      log('An error occurred while deleting the cache.');
      log(error);
    }
  };

  if (!props.isEnabled) {
    return children;
  } else {

    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      refreshCacheAndReload();
      return null;
    }

    return React.createElement(
      CacheBusterContext.Provider,
      {
        value: { checkCacheStatus }
      },
      children
    );
  }
}

const useCacheBuster = () => {
  const context = useContext(CacheBusterContext);
  if (context === undefined || context === null) {
    throw new Error(
      'useCacheBuster must be used within a CacheBuster component.'
    );
  }
  return context;
};

export { CacheBuster, useCacheBuster };
