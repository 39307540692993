class Constants {
     public MINUTES_PER_HOUR: number = 60;
     public SECONDS_PER_MINUTE: number = 60;
     public HOURS_PER_DAY: number = 24;
     public MINUTES_PER_DAY: number = this.MINUTES_PER_HOUR * this.HOURS_PER_DAY;
     public WORK_HOURS_PER_DAY: number = 8;
     public WORK_MINUTES_PER_DAY: number = this.MINUTES_PER_HOUR * this.WORK_HOURS_PER_DAY;
     public WORK_HOURS_PER_WEEK: number = 40;

     // Requirements: Minimium 8 characters.  Minimum 1 character from each of the 4 character classes: lower case letter, capital letter, number, special character
     public PASSWORD_PATTERN: RegExp = /(?=.{8})(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;

     public TWO_CAPITAL_LETTERS_PATTERN: RegExp = /^[A-Z]{2}$/;

     public VALID_QB_NAME_CHARACTERS: RegExp = /^[a-zA-Z0-9,\.\?@&!#'~\*\-–_\+;\(\)\s$%^={}[\]|\\"<>/`]+$/;
     public VALID_QB_CHARACTERS: RegExp = /^[a-zA-Z0-9,\.\?@&!#'~\*\-–_\+;\(\)\s$%^={}[\]|\\"<>/`:]+$/;

     public US_POSTAL_CODE_PATTERN: RegExp = /^[0-9]{5}(-[0-9]{4})?$/;
     public CANADA_POSTAL_CODE_PATTERN: RegExp = /^[A-Z0-9]{3}[ ]([A-Z0-9]{3})?$/;

     public DECIMAL_NUMBER_PATTERN: RegExp = /^\d*\.?\d*$/;

     // matches url with http(s)://string.string.string...etc,  does not include path. Case insensitive 
     public BASE_URL_PATTERN: RegExp = /^http(s)?:\/\/[a-z0-9-]+(\.[a-z0-9-]+)*(:[0-9]+)?/i;

     public USER_ROLES: string[] = ['Firm Owner', 'Office Manager', 'Project Manager', 'Employee'];

     public US_STATES_ABBREV: string[] = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
     public CANADA_TERRITORIES_ABBREV: string[] = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

     public DAYS_OF_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

     public B2C_CLAIM_EMAIL: string = 'email';
     public B2C_CLAIM_IS_ACTIVE: string = 'isActive';
     public B2C_CLAIM_USER_ID: string = 'userId';
     public B2C_CLAIM_ACTION: string = 'action';
     public B2C_CLAIM_POLICY: string = 'acr';
}

export default new Constants;